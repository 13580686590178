import React, { useEffect } from 'react'
import { pageTitle } from '../../helper'
import Accordion from '../Accordion'
import Button from '../Button'
import CardStype3 from '../Card/CardStype3'
import ContactForm from '../ContactForm'
import ContactInfo from '../ContactInfo'
import FunFact from '../FunFact'
import HeroStyle2 from '../Hero/HeroStyle2'
import IconBox from '../IconBox'
import Modal from '../Modal'
import Section from '../Section'
import SectionHeading from '../SectionHeading'
import BlogSlider from '../Slider/BlogSlider'
import ImageBoxSliderStyle1 from '../Slider/ImageBoxSliderStyle1'
import RoadmapSlider from '../Slider/RoadmapSlider'
import TeamSlider from '../Slider/TeamSlider'
import Spacing from '../Spacing'

export default function Home3() {
  pageTitle('Home-3');
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const accordionData1 = [
    {
      question: 'How to Mint an NFT?',
      answer: 'There are many variations of pass ges of Lorem Ipsum available, but the majority have suffered alterat ion in some form. There are many variations of passages. There are many variations of pass ges of Lorem Ipsum available, but the majority have suffered alterat ion in some form. There are many variations of passages.'
    },
    {
      question: 'What happens once purchase NFT?',
      answer: 'There are many variations of pass ges of Lorem Ipsum available, but the majority have suffered alterat ion in some form. There are many variations of passages. There are many variations of pass ges of Lorem Ipsum available, but the majority have suffered alterat ion in some form. There are many variations of passages.'
    },
    {
      question: 'How can I obtain NFTs?',
      answer: 'There are many variations of pass ges of Lorem Ipsum available, but the majority have suffered alterat ion in some form. There are many variations of passages. There are many variations of pass ges of Lorem Ipsum available, but the majority have suffered alterat ion in some form. There are many variations of passages.'
    },
    {
      question: 'Do NFTs appreciate in value?',
      answer: 'There are many variations of pass ges of Lorem Ipsum available, but the majority have suffered alterat ion in some form. There are many variations of passages. There are many variations of pass ges of Lorem Ipsum available, but the majority have suffered alterat ion in some form. There are many variations of passages.'
    }
  ]
  return (
    <>
      <HeroStyle2
        title='A Cat named Fatso, who assumed the mantle of "Keyboard Cat"'
        mintNumber=''
        mintMax=''
        mintPrice='0.02'
        mintDeathLine='22 July'
        bgUrl='/images/hero_bg_1.jpeg'
        heroImageUrl='/images/hero_img_2.gif'
        variant='cs-type2 text-center'
        overlay='0.4'
        bubble
      />
      <Spacing lg='100' md='70' />
      <Section className="container">
        <Section className="row">
          <Section className="col-lg-4">
            <IconBox
              variant='cs-type1 text-center'
            >
              <img src='https://media1.tenor.com/m/L-ZtvjHa_g4AAAAd/rave-cat.gif' />
            </IconBox>
            <Spacing lg='25' md='25' />
          </Section>
          <Section className="col-lg-4">
            <IconBox
              variant='cs-type1 text-center'
            >
              <img src='https://media1.tenor.com/m/L-ZtvjHa_g4AAAAd/rave-cat.gif' />
            </IconBox>
            <Spacing lg='25' md='25' />
          </Section>
          <Section className="col-lg-4">
            <IconBox
              variant='cs-type1 text-center'
            >
              <img src='https://media1.tenor.com/m/L-ZtvjHa_g4AAAAd/rave-cat.gif' />
            </IconBox>
            <Spacing lg='25' md='25' />
          </Section>
        </Section>
      </Section>
      <Section tag='section' id='about'>
        <Spacing lg='100' md='70' />
        <Section className="container">
          <Section className="row align-content-center">
            <Section className="col-lg-6">
              <Spacing lg='0' md='40' />
              <Section className='cs-right_space_40'>
                <SectionHeading
                  title='Just a cat'
                />
                <Spacing lg='20' md='20' />
                <Section tag='p'>
                  $KCAT Isn't Just Any Meme Token; It's A Virtual Jingle, The Soundtrack Of The Blockchain, And A Catalyst For Digital Innovation. Imagine A World Where Finance Dances To The Tune Of Technology, Where Each Transaction Plays A Note In The Symphony Of Progress. $KCAT Is For Those Who Hear The Future Before It Arrives, For Pioneers Who Mix The Beats Of Tomorrow, Crafting A New Vibe In The Economy And Tech Sphere.
                  <b>Nahhhhh, degen it is literally a cat creating music on a keyboard
                  </b>
                </Section>
                <Spacing lg='25' md='15' />
                <Section className="cs-btn_group">
                  <Modal modalType='mint' btnText='Buy' />
                  {/* <Button
                    variant='cs-btn cs-color1'
                    btnLink='/'
                    btnText='Whitelist Now'
                  /> */}
                </Section>
              </Section>
            </Section>
            <Section className="col-lg-6">

              <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/J---aiyznGQ?si=jHMDgn0ZLmjF2krY&amp;controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </Section>
          </Section>
        </Section>
        <Spacing lg='100' md='70' />
      </Section>

      {/* <Spacing lg='75' md='45' /> */}
      {/* <ImageBoxSliderStyle1 /> */}
      {/* <Spacing lg='95' md='65' /> */}
      <Section className="container">
        <Section className="text-center">
          <SectionHeading
            title='0x00000000'
            subtitle='Contract'
          />
          <SectionHeading
            title='1,000,000,000'
            subtitle='Supply'
          />
        </Section>
        <Spacing lg='50' md='30' />
        <Section className="row">
          <Section className="col-xl-3 col-md-6">
            <CardStype3
              number='1'
              title='Connect Your Wallet'
            />
            <Section className="cs-height_25 cs-height_lg_25"></Section>
          </Section>
          <Section className="col-xl-3 col-md-6">
            <CardStype3
              number='2'
              title='Select Your Quantity'
            />
            <Section className="cs-height_25 cs-height_lg_25"></Section>
          </Section>
          <Section className="col-xl-3 col-md-6">
            <CardStype3
              number='3'
              title='Transaction Confirm'
            />
            <Section className="cs-height_25 cs-height_lg_25"></Section>
          </Section>
          <Section className="col-xl-3 col-md-6">
            <CardStype3
              number='4'
              title='Recive Your Cats'
            />
            <Section className="cs-height_25 cs-height_lg_25"></Section>
          </Section>
        </Section>
      </Section>
      <Section tag='section' id='roadmap'>
        <Spacing lg='70' md='40' />
        <Section className="container">
          <Section className="text-center">
            <SectionHeading
              title='Other cats loved too'
            />
          </Section>
          <Spacing lg='75' md='55' />
          {/* <Section className="cs-accent_seperator_1 cs-accent_color"></Section> */}
          <RoadmapSlider />
        </Section>
      </Section>
      <Spacing lg='100' md='70' />
    </>
  )
}
