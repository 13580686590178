import { Icon } from '@iconify/react'
import React from 'react'
import Button from '../Button'
import Section from '../Section'
import './cta.scss'

export default function Cta() {
  return (
    <Section className="cs-cta cs-style1 cs-accent_bg text-center">
      <Section tag='h2' className="cs-cta_title cs-white_color cs-font_64 cs-font_36_sm cs-m0">RIP FATSO<br />1987</Section>
      <Section className="cs-height_10 cs-height_lg_10" />
      <Section className="cs-cta_subtitle cs-white_color">
        $KCAT isn't just a token; it's the heartbeat of innovation, a melody for the future, a tune for the forward-thinkers. It's evident the future will be written by those who play along with advancements like $KCAT, erasing limits & orchestrating a new chapter in finance and technology. Nahhhhh, degen it is literally a cat creating music on a keyboard

      </Section>
      <Section className="cs-height_30 cs-height_lg_30" />
    </Section>
  )
}
